import React from 'react';
import PropTypes from 'prop-types';

import { withPrefix } from 'gatsby';
import { Waypoint } from 'react-waypoint';

class WaypointImage extends React.Component {
  constructor(props) {
    super(props);
    this.state = { imgSrc: this.props.start };
  }

  toggleImgSrc() {
    this.setState({
      imgSrc: this.props.end,
    });
  }

  toggleImgSrcBack() {
    this.setState({
      imgSrc: this.props.start,
    });
  }

  render() {
    const self = this;
    return (
      <Waypoint
        onEnter={self.toggleImgSrc.bind(self)}
        onLeave={self.toggleImgSrcBack.bind(self)}
        bottomOffset="100px"
      >
        <img src={withPrefix(self.state.imgSrc)} alt={this.props.alt} />
      </Waypoint>
    );
  }
}

WaypointImage.propTypes = {
  start: PropTypes.string.isRequired,
  end: PropTypes.string.isRequired,
};

export default WaypointImage;
