import React from 'react';

import 'typeface-barlow';

import { withStyles } from '@material-ui/core/styles';
import withRoot from '../withRoot';

import Layout from '../components/Layout';
import Body from '../components/Body';

const styles = () => ({
  overrides: {
    MuiAppBar: {
      colorDefault: {
        backgroundColor: 'transparent',
      },
    },
  },
});

const IndexPage = ({ location }) => (
  <div className="home">
    <Layout location={location} titleSuffix="Organize Your Move">
      <Body />
    </Layout>
  </div>
);

export default withRoot(withStyles(styles)(IndexPage));
