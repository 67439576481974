import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { withPrefix } from 'gatsby';

import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowCircleRight } from '@fortawesome/pro-regular-svg-icons';

import { orangePinkGradient, blackGradient } from '../theme/theme';
import container from '../theme/styles/container';
import section from '../theme/styles/section';
import checklist from '../images/checklist.svg';
import progress from '../images/progress.svg';
import resources from '../images/resources.svg';
import WaypointImage from './WaypointImage';
import ResourceSearchBlock from './ResourceSearchBlock';
import Link from './Link';

const styles = theme => ({
  ...container,
  ...section,
  root: {
    // overflowX: 'hidden',
    marginTop: -69,
    '@media (min-width: 768px)': {
      marginTop: -71,
    },
  },
  hero: {
    backgroundColor: theme.palette.common.lightBlack,
    backgroundImage: blackGradient,
    paddingBottom: '8vw',
    paddingTop: 80,
    position: 'relative',
    overflow: 'hidden',
    [theme.breakpoints.up('sm')]: {
      minHeight: 683,
      paddingTop: 125,
    },
    [theme.breakpoints.up('md')]: {
      minHeight: 0,
      paddingTop: 80,
    },
  },
  heroText: {
    position: 'relative',
    zIndex: 3,
  },
  heroTextInner: {
    paddingTop: theme.spacing.unit * 2,
    paddingBottom: theme.spacing.unit * 2,
    [theme.breakpoints.up('md')]: {
      minHeight: '40vw',
      maxHeight: 550,
    },
    [theme.breakpoints.up('lg')]: {
      minHeight: '35vw',
      maxHeight: 550,
    },
  },
  heroChevron: {
    position: 'absolute',
    bottom: -2,
    left: '-2%',
    right: '-2%',
    zIndex: 5,
    maxWidth: '104%',
    width: '104%',
  },
  heroScreens: {
    position: 'relative',
    textAlign: 'right',
    width: '100%',
    zIndex: 1,
    marginBottom: '-15vw',
    marginTop: theme.spacing.unit * 2,
    paddingLeft: theme.spacing.unit * 2,
    [theme.breakpoints.up('sm')]: {
      position: 'absolute',
      bottom: '3vw',
      paddingLeft: 0,
      maxHeight: 350,
      marginBottom: 0,
      marginTop: 0,
    },
    [theme.breakpoints.up('md')]: {
      bottom: '4vw',
      right: 0,
      maxHeight: 'none',
    },
  },
  heroDesktop: {
    boxShadow: '15px 10px 50px #111018',
    marginBottom: '4vw',
    width: '100%',
    marginLeft: '-10%',
    marginRight: '-25%',
    [theme.breakpoints.up('sm')]: {
      width: '80%',
      maxWidth: 650,
      marginLeft: '-10%',
    },
    [theme.breakpoints.up('md')]: {
      width: '47%',
      maxWidth: 680,
      marginRight: -150,
      marginLeft: -75,
    },
    [theme.breakpoints.up('xl')]: {
      maxWidth: 760,
      marginRight: -80,
    },
  },
  heroMobile: {
    boxShadow: '15px 10px 50px #111018',
    position: 'relative',
    zIndex: 2,
    width: '35%',
    [theme.breakpoints.up('sm')]: {
      width: '25%',
      maxWidth: 200,
    },
    [theme.breakpoints.up('md')]: {
      width: '15%',
      maxWidth: 220,
    },
    [theme.breakpoints.up('xl')]: {
      maxWidth: 250,
    },
  },
  whiteBackground: {
    backgroundColor: '#fff',
  },
  appBarSpacer: theme.mixins.toolbar,
  sidebarPaper: {
    position: 'fixed',
  },
  gradientButtonOuter: {
    borderRadius: 3,
    display: 'block',
    overflow: 'hidden',
    marginTop: 20,
    [theme.breakpoints.up('sm')]: {
      display: 'inline-block',
    },
  },
  gradientButtonInner: {
    borderImage: orangePinkGradient,
    borderImageSlice: 1,
    borderWidth: 3,
    color: '#fff',
    fontSize: '1.5rem',
    paddingLeft: theme.spacing.unit * 4,
    paddingRight: theme.spacing.unit * 4,
    '& > span': {
      zIndex: 2,
    },
    '&:after': {
      backgroundImage: orangePinkGradient,
      borderBottom: 0,
      height: '100%',
    },
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  gradientButtonIcon: {
    marginLeft: 5,
    width: 24,
    height: 24,
  },
  whiteText: {
    color: '#fff',
  },
  iconContainer: {
    marginTop: theme.spacing.unit * 4,
    textAlign: 'center',
  },
  icon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: 80,
    marginBottom: theme.spacing.unit * 2,
  },
  pipe: {
    color: theme.palette.secondary.main,
    fontWeight: 400,
  },
  phoneSection: {
    paddingTop: theme.spacing.unit * 3,
    paddingBottom: theme.spacing.unit * 3,

    '&:first-child': {
      paddingTop: 0,
    },
    '&:last-child': {
      paddingBottom: 0,
    },
    [theme.breakpoints.up('md')]: {
      paddingTop: 0,
      paddingBottom: 0,
    },
  },
  phoneContainer: {
    position: 'relative',
    margin: '0 auto',
    [theme.breakpoints.down('md')]: {
      maxWidth: 500,
    },
  },
  phoneContainerRight: {
    textAlign: 'right',
  },
  phone: {
    width: 180,
    [theme.breakpoints.up('md')]: {
      width: 200,
    },
    [theme.breakpoints.up('lg')]: {
      width: 240,
    },
  },
  // phoneRight: {
  //   [theme.breakpoints.up('md')]: {
  //     marginRight: -100,
  //   },
  //   [theme.breakpoints.up('lg')]: {
  //     marginRight: 0,
  //   },
  // },
  // phoneLeft: {
  //   [theme.breakpoints.up('md')]: {
  //     marginLeft: -100,
  //   },
  //   [theme.breakpoints.up('lg')]: {
  //     marginLeft: 0,
  //   },
  // },
  popover: {
    '& > img': {
      background: '#fff',
      position: 'absolute',
      top: 0,
      bottom: 0,
      width: '80%',
      maxWidth: 375,
      [theme.breakpoints.up('md')]: {
        margin: 'auto',
        width: '90%',
      },
    },
  },
  popoverRight: {
    '& > img': {
      boxShadow: '10px 10px 25px rgba(54, 52, 75, 0.3)',
      left: theme.spacing.unit,
      right: theme.spacing.unit,
      margin: 'auto auto auto 0',
      [theme.breakpoints.up('lg')]: {
        left: 100,
        right: 'auto',
      },
    },
  },
  popoverLeft: {
    '& > img': {
      boxShadow: '-10px 10px 25px rgba(54, 52, 75, 0.3)',
      left: theme.spacing.unit,
      right: theme.spacing.unit,
      margin: 'auto 0 auto auto',
      [theme.breakpoints.up('lg')]: {
        left: 'auto',
        right: 100,
      },
    },
  },
  footerChevron: {
    display: 'block',
    width: '100%',
    position: 'absolute',
    left: 0,
    right: 0,
    marginTop: -2,
    zIndex: 5,
  },
  // phoneScreens: {
  //   overflowX: 'hidden',
  // },
});

const Body = ({ classes }) => (
  <div className={classes.root}>
    <section className={classes.hero}>
      <div className={classNames(classes.container, classes.heroText)}>
        <Grid container alignItems="center" className={classes.heroTextInner}>
          <Grid item md={6}>
            <Typography
              component="h1"
              variant="h1"
              className={classes.whiteText}
            >
              Move your way.
            </Typography>
            <Typography
              component="p"
              variant="subtitle1"
              className={classes.whiteText}
            >
              Whether you’re moving across the street or across the country,
              movetrac&reg; has everything you need to help keep everything about
              your move on track.
            </Typography>
            <span className={classes.gradientButtonOuter}>
              <Button
                variant="outlined"
                href="https://app.movetrac.com/"
                className={classes.gradientButtonInner}
              >
                Try it Free!
                <FontAwesomeIcon
                  icon={faArrowCircleRight}
                  className={classes.gradientButtonIcon}
                />
              </Button>
            </span>
          </Grid>
        </Grid>
      </div>
      <div className={classes.heroScreens}>
        <img
          src={withPrefix('/img/hero-mobile.png')}
          alt=""
          className={classes.heroMobile}
        />
        <img
          src={withPrefix('/img/hero-desktop.png')}
          alt=""
          className={classes.heroDesktop}
        />
      </div>
      <img
        src={withPrefix('/img/top.svg')}
        alt=""
        className={classes.heroChevron}
      />
    </section>

    <section className={classNames(classes.whiteBackground, classes.section)}>
      <div className={classes.container}>
        <Typography component="h2" variant="h2" align="center">
          Everything you need for a smooth move.
        </Typography>
        <Grid container spacing={24}>
          <Grid item md={4} xs={12} className={classes.iconContainer}>
            <div className={classes.icon}>
              <img src={checklist} alt="" />
            </div>
            <Typography component="h3" variant="h3">
              Tailored Checklist
            </Typography>
            <Typography component="p">
              Based on details you share with us, we’ll build a to-do list
              that’s unique to your move.
            </Typography>
          </Grid>

          <Grid item md={4} xs={12} className={classes.iconContainer}>
            <div className={classes.icon}>
              <img src={progress} alt="" />
            </div>
            <Typography component="h3" variant="h3">
              Track Your Progress
            </Typography>
            <Typography component="p">
              Keep track of the tasks you’ve completed and get alerted about
              tasks you’ve missed.
            </Typography>
          </Grid>

          <Grid item md={4} xs={12} className={classes.iconContainer}>
            <div className={classes.icon}>
              <img src={resources} alt="" />
            </div>
            <Typography component="h3" variant="h3">
              Helpful Resources
            </Typography>
            <Typography component="p">
              Our extensive library of resources and articles will help you get
              the job done right.
            </Typography>
          </Grid>
        </Grid>
      </div>
    </section>

    <section className={classNames(classes.container, classes.phoneScreens, classes.section)}>
      <Grid
        container
        spacing={24}
        alignItems="center"
        className={classes.phoneSection}
      >
        <Grid item lg={6} md={7} xs={12}>
          <Typography component="h3" variant="h2">
            1 <span className={classes.pipe}>|</span> Create Your Account
          </Typography>
          <Typography component="p" variant="subtitle1">
            Signing up with movetrac&reg; is simple. All you need to create your
            account is an email address. Once you’ve signed up, you’re ready to
            get moving!
          </Typography>
        </Grid>

        <Grid
          item
          lg={6}
          md={5}
          xs={12}
          className={classNames(
            classes.phoneContainer,
            classes.phoneContainerRight,
          )}
        >
          <img
            src={withPrefix('/img/signup-screen.png')}
            alt=""
            className={classNames(classes.phone, classes.phoneRight)}
          />
          <div className={classNames(classes.popover, classes.popoverRight)}>
            <WaypointImage
              start="/img/signup-start.svg"
              end="/img/signup-animation.svg"
              alt="A simple email signup form."
            />
          </div>
        </Grid>
      </Grid>

      <Grid
        container
        spacing={24}
        direction="row-reverse"
        alignItems="center"
        className={classes.phoneSection}
      >
        <Grid item lg={6} md={7} xs={12}>
          <Typography component="h3" variant="h2">
            2 <span className={classes.pipe}>|</span> Tailor Your To-Dos
          </Typography>
          <Typography component="p" variant="subtitle1">
            We’ll ask you some basic questions about your move, and then we’ll
            create your checklist based on the answers you provide.
          </Typography>
        </Grid>

        <Grid item lg={6} md={5} xs={12} className={classes.phoneContainer}>
          <img
            src={withPrefix('/img/questions-screen.png')}
            alt=""
            className={classNames(classes.phone, classes.phoneLeft)}
          />
          <div className={classNames(classes.popover, classes.popoverLeft)}>
            <WaypointImage
              start="/img/questions-start.svg"
              end="/img/questions-animation.svg"
              alt="A checklist of personal moving needs."
            />
          </div>
        </Grid>
      </Grid>

      <Grid
        container
        spacing={24}
        alignItems="center"
        className={classes.phoneSection}
      >
        <Grid item lg={6} md={7} xs={12}>
          <Typography component="h3" variant="h2">
            3 <span className={classes.pipe}>|</span> Track Your Progress
          </Typography>
          <Typography component="p" variant="subtitle1">
            We’ll show you a progress bar that updates whenever you check off
            completed tasks. You’ll also get alerted about upcoming and overdue
            tasks!
          </Typography>
        </Grid>

        <Grid
          item
          lg={6}
          md={5}
          xs={12}
          className={classNames(
            classes.phoneContainer,
            classes.phoneContainerRight,
          )}
        >
          <img
            src={withPrefix('/img/tasks-screen.png')}
            alt=""
            className={classNames(classes.phone, classes.phoneRight)}
          />
          <div className={classNames(classes.popover, classes.popoverRight)}>
            <WaypointImage
              start="/img/tasks-start.svg"
              end="/img/tasks-animation.svg"
              alt="A list of to-do items getting marked as completed."
            />
          </div>
        </Grid>
      </Grid>
    </section>

    <section className={classNames(classes.whiteBackground, classes.section)}>
      <div className={classes.container}>
        <ResourceSearchBlock />
      </div>
    </section>

    <img
      src={withPrefix('/img/bottom.svg')}
      alt=""
      className={classes.footerChevron}
    />
  </div>
);

Body.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Body);
