import React from 'react';
import { navigate } from 'gatsby';
import Typography from '@material-ui/core/Typography';
import find from 'lodash/find';

import SearchInput from './SearchInput';

class ResourceSearchBlock extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      searchQuery: '',
      category: 'all',
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChange(name, value) {
    this.setState({
      ...this.state,
      [name]: value,
    });
  }

  handleSubmit() {
    navigate(
      `/resources/?query=${this.state.searchQuery}&category=${
        this.state.category
      }`,
    );
  }

  getOrCreateIndex = () =>
    this.index
      ? this.index
      : // Create an elastic lunr index and hydrate with graphql query results
        Index.load(this.props.data.siteSearchIndex.index);

  searchResults = query => {
    this.index = this.getOrCreateIndex();
    return this.index
      .search(query)
      .map(({ ref }) =>
        find(this.props.data.allMarkdownRemark.edges, o => o.node.id === ref),
      );
  };

  render() {
    return (
      <div>
        <Typography component="h2" variant="h2" align="center">
          Our moving experts are here to help.
        </Typography>
        <SearchInput
          searchQuery={this.state.searchQuery}
          category={this.state.category}
          onSubmit={this.handleSubmit}
          onChange={this.handleChange}
        />
      </div>
    );
  }
}

export default ResourceSearchBlock;
